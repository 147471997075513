import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import 'react-quill/dist/quill.snow.css';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
// @mui
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'react-dropzone-uploader/dist/styles.css';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import RHFSelect from '../../../components/hook-form/RHFSelect';
import tracker from '../../../api/tracker';
import RHFFileInput from '../../../components/hook-form/RHFFileInput';
import Label from '../../../components/Label';

// ----------------------------------------------------------------------

export default function EventForm({ event }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [date, setDate] = useState(event ? moment(event.date, 'YYYY-MM-DD') : null);
  const [endDate, setEndDate] = useState(event ? moment(event.date_end, 'YYYY-MM-DD') : null);

  useEffect(() => {
    if (event) {
      const fields = ['name', 'description', 'date'];
      fields.forEach((field) => setValue(field, event[field]));
    }
  }, [event]);

  const EventSchema = Yup.object().shape({
    name: Yup.string().required(t('Required')),
    // password: Yup.string().required(t('Required')),
    description: Yup.string().required('Required'),
    // body: Yup.string().required(t('Required')),
    // image: Yup.mixed().test("fileSize", "The file is too large", (value) => {
    //   console.log(value)
    //   return true
    // }),
    // thumbnail: Yup.mixed().test("fileSize", "The file is too large", (value) => {
    //   console.log(value)
    //   return true
    // }),
  });

  const defaultValues = {
    name: '',
    description: '',
    date: '',
    image: null,
    thumbnail: null,
  };
  // if(!user)
  //   user = defaultValues

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    if (data.image) {
      data.image = data.image[0];
    }
    if (data.thumbnail) {
      data.thumbnail = data.thumbnail[0];
    }
    data.date = date.format('YYYY-MM-DD');
    data.date_end = endDate.format('YYYY-MM-DD');

    if (!event)
      await tracker.post('events', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    else
      await tracker.post(`events/update/${event.hashid}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    navigate('/dashboard/events', { replace: true });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="name" label={t('Name')} />
        </Stack>
        <RHFTextField name="description" label={t('Description')} />

        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label={t('Date_event')}
            inputFormat="MM/DD/YYYY"
            value={date}
            onChange={setDate}
            renderInput={(params) => <TextField {...params} />}
          />
          <DesktopDatePicker
            label={t('end_date')}
            inputFormat="MM/DD/YYYY"
            name="date_end"
            value={endDate}
            onChange={setEndDate}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <div style={{ marginTop: '80px' }}>
          <Label>{t('Imagen')}</Label>
          <RHFFileInput name="image" fileUrl={event && event.image ? event.image : null} />
        </div>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('Create')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
